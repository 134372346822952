import { Box, Grid, Typography } from '@mui/material';
import useNonInitialEffect from '@versiondos/hooks';
import { loginWithSMSCodeThunk, requestSMSCodeThunk } from 'actions/login/LoginActions';
import { hero1 } from 'assets/images';
import MarketplaceInfo from 'components/Booking/MarketplaceInfo/MarketplaceInfo';
import { FullWidthImage, Heading } from 'components/Commons';
import { PrimaryButton } from 'components/UI/Buttons';
import { CustomField } from 'components/UI/FormsPart';
import { CustomPhoneField } from 'components/UI/FormsPart/CustomPhoneField/CustomPhoneField';
import FormErrorList from 'components/UI/FormsPart/FormErrorList/FormErrorList';
import { BookingLayout } from 'components/UI/Layout';
import React, { useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { LoginState, LoginStatus } from 'reducers/login/LoginState';
import { MarketplaceState } from 'reducers/marketplace/MarketplaceState';
import { RootState } from 'store';
import './BookingSignin.styles.scss';
import UnderlinedButton from 'components/UI/Buttons/UnderlinedButton/UnderlinedButton';
import { clearAlerts, showErrorAlert } from 'actions/alerts/AlertsActions';
import { BookingStep } from 'components/Booking/Booking';

interface BookingSinginProps {
    onContinue: () => void;
}

// Two minutes
const TIMEOUT = 2 * 60 * 1000;

const BookingSignin: React.FC<BookingSinginProps> = props => {
    const dispatch = useDispatch();

    const [code, setCode] = useState('');
    const [phone, setPhone] = useState('');
    const [isRequested, setIsRequested] = useState(false);
    const [timer, setTimer] = useState<NodeJS.Timeout | null>(null);
    const [timerStartTime, setTimerStartTime] = useState<number | null>(null);

    const { status: loginStatus, loading: isLoading } = useSelector<RootState, LoginState>(
        state => state.login
    );
    const marketplaceState = useSelector<RootState, MarketplaceState>(state => state.marketplace);

    const phoneChangeHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
        setPhone(event.currentTarget.value);
    };

    const codeChangeHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
        setCode(event.currentTarget.value);
    };

    const requestCodeHandler = (resend = false) => {
        if (isPhoneFormInvalid) {
            return;
        }

        dispatch(clearAlerts());

        // If timer it's set skip sending the code & its resending process
        if (timer !== null && resend) {
            const remainingTime = getTimerRemainingMinutes();

            dispatch(
                showErrorAlert(
                    `You already requested a code, please wait ${remainingTime} before sending a new one.`,
                    BookingStep.Signin
                )
            );

            return;
        }

        startTimer();

        dispatch(requestSMSCodeThunk(clearedPhone));
    };

    const startTimer = () => {
        setTimerStartTime(new Date().getTime());

        setTimer(
            setTimeout(() => {
                resetTimer();
            }, TIMEOUT)
        );
    };

    const resetTimer = () => {
        setTimer(null);
        setTimerStartTime(null);
    };

    const getTimerRemainingMinutes = () => {
        if (!timerStartTime) {
            return '';
        }

        const remainingTimeMs = TIMEOUT - (new Date().getTime() - timerStartTime);
        const remainingTimeMinutes = Math.round(remainingTimeMs / 60000);

        return `${remainingTimeMinutes === 0 ? 1 : remainingTimeMinutes} ${
            remainingTimeMinutes > 1 ? 'minutes' : 'minute'
        }`;
    };

    const confirmCodeHandler = () => {
        if (isCodeFormInvalid) {
            return;
        }

        dispatch(clearAlerts());
        dispatch(loginWithSMSCodeThunk(clearedPhone, code));
    };

    const clearedPhone = useMemo(() => {
        return phone.replace(/[()\-_ ]/g, '');
    }, [phone]);

    const isPhoneInvalid = useMemo(() => {
        return clearedPhone.length < 10 && clearedPhone.length > 0;
    }, [clearedPhone]);

    const isPhoneFormInvalid = useMemo(() => {
        return clearedPhone.length < 10;
    }, [clearedPhone]);

    const isCodeFormInvalid = useMemo(() => {
        return code.length < 6;
    }, [code]);

    useNonInitialEffect(() => {
        // If the user already requested a code, we should show the code form
        if (loginStatus === LoginStatus.RequestSMSCodeSuccess) {
            setIsRequested(true);
        }

        // Customer signed in successfully
        if (loginStatus === LoginStatus.Success) {
            props.onContinue();
        }
    }, [loginStatus]);

    const requestCodeForm = (
        <>
            <Heading title={`Sign in to ${marketplaceState.marketplace.name}`} />

            <CustomPhoneField
                required
                value={phone}
                label="Phone Number"
                error={isPhoneInvalid}
                onChange={phoneChangeHandler}
            />

            <FormErrorList step={BookingStep.Signin} />

            <Box
                sx={{
                    mt: {
                        xs: 'auto',
                        md: 4
                    },
                    mx: {
                        xs: '-24px'
                    },
                    px: {
                        xs: 3
                    },
                    borderTop: {
                        xs: 'solid 1px #D4D4D4',
                        md: 'none'
                    },
                    pt: {
                        xs: 2
                    }
                }}
            >
                <PrimaryButton
                    label="Request Code"
                    sx={{
                        width: '100%',
                        mb: {
                            xs: '0px !important'
                        }
                    }}
                    disabled={isPhoneFormInvalid || isLoading}
                    onClick={() => requestCodeHandler()}
                />
            </Box>
        </>
    );

    const confirmCodeForm = (
        <>
            <Heading title={<Typography>We sent you a code to {phone}</Typography>} />

            <CustomField
                required
                type="text"
                label="Enter the code"
                inputProps={{
                    maxLength: 6,
                    pattern: '[0-9]{6}'
                }}
                onChange={codeChangeHandler}
            />

            <FormErrorList step={BookingStep.Signin} />

            <PrimaryButton
                label="Sign in"
                sx={{ mt: 4, width: '100%' }}
                disabled={isCodeFormInvalid || isLoading}
                onClick={confirmCodeHandler}
            />

            <Box textAlign="center">
                <UnderlinedButton label="Resend code" onClick={() => requestCodeHandler(true)} />
            </Box>
        </>
    );

    return (
        <BookingLayout
            sx={{
                '.BookingLayout-left-column': {
                    'display': 'flex',

                    'pb': {
                        xs: '16px !important'
                    },
                    '> div': {
                        margin: {
                            xs: 0,
                            md: 'auto'
                        },
                        display: {
                            xs: 'flex',
                            md: 'block'
                        },
                        flexDirection: 'column'
                    }
                },
                '.BookingLayout-right-column': {
                    display: {
                        xs: 'none',
                        md: 'block'
                    }
                }
            }}
            leftColumn={
                <Grid item xs={12}>
                    <MarketplaceInfo
                        marketplace={marketplaceState.marketplace}
                        sx={{ mb: { xs: 6, lg: 4.5 } }}
                    />

                    {isRequested ? confirmCodeForm : requestCodeForm}
                </Grid>
            }
            rightColumn={<FullWidthImage src={hero1} alt="Cuddles Booking flow" />}
        />
    );
};

export default BookingSignin;
