import { Box, Grid } from '@mui/material';
import { BookingStep } from 'components/Booking/Booking';
import { Heading } from 'components/Commons';
import PetChip from 'components/Pet/PetChip/PetChip';
import { FormError } from 'components/UI/FormsPart';
import CollapsibleRadio from 'components/UI/FormsPart/CollapsibleRadio/CollapsibleRadio';
import FormErrorList from 'components/UI/FormsPart/FormErrorList/FormErrorList';
import { BookingLayout } from 'components/UI/Layout';
import { useServices } from 'hooks/useServices';
import { EditBooking, Marketplace, Pet, Service } from 'model';
import React, { useMemo } from 'react';
import { filterServicesForPet, searchForServicePetPrice } from 'utils/servicesUtils';

interface BookingSelectServiceProps {
    booking: EditBooking;
    marketplace: Marketplace;
    //
    onChange: (booking: EditBooking) => void;
    onContinue: () => void;
}

const BookingSelectService: React.FC<BookingSelectServiceProps> = props => {
    const [selectedService, setSelectedService] = React.useState(
        props.booking.petsToBooking[0].service?.id.toString()
    );

    const services = useServices();

    const pet: Pet = props.booking.petsToBooking[0].pet;

    const formInvalid = props.booking.petsToBooking.some(item => item.service === undefined);

    const servicesList = useMemo(() => {
        return filterServicesForPet(services, pet)
            .map(service => {
                const price = searchForServicePetPrice(service, props.booking.petsToBooking[0].pet);

                return {
                    ...service,
                    price: price.toString()
                };
            })
            .filter(service => parseInt(service.price) > 0)
            .sort(function (a, b) {
                if (a.name < b.name) {
                    return -1;
                }
                if (a.name > b.name) {
                    return 1;
                }
                return 0;
            });
    }, [services, pet]);

    const changeHandler = (service: Service) => {
        setSelectedService(service.id.toString());

        const price = searchForServicePetPrice(service, props.booking.petsToBooking[0].pet);

        const newService: Service = {
            id: service.id,
            name: service.name,
            description: service.description,
            fixedPrice: service.fixedPrice,
            variablePrice: service.variablePrice,
            price: price.toString(),
            petList: service.petList,
            variableDuration: service.variableDuration,
            durationHours: service.durationHours,
            durationMinutes: service.durationMinutes,
            staffIds: service.staffIds
        };

        props.onChange({
            ...props.booking,
            petsToBooking: [
                {
                    ...props.booking.petsToBooking[0],
                    service: newService
                }
            ]
        });
    };

    return (
        <BookingLayout
            showFooter={{
                disabled: formInvalid,
                onClick: props.onContinue
            }}
            showResume={props.booking}
            leftColumn={
                <Grid item xs={12}>
                    <Heading boxProps={{ mb: 3 }} title={<small>Select service</small>} />

                    <FormErrorList step={BookingStep.ServiceSelect} />

                    {servicesList.length === 0 ? (
                        <FormError
                            icon
                            title={
                                "Oops! Looks like we don't have any services available for your pet's size or type right now. "
                            }
                            boxProps={{
                                sx: {
                                    'flex': '0 0 100%',
                                    'mt': '24px !important',
                                    'mb': '16px !important',
                                    ' > div': {
                                        fontSize: 14,
                                        lineHeight: 1.4,
                                        padding: '14px',
                                        fontWeight: 400,
                                        color: '#222222',
                                        textAlign: 'left',
                                        borderRadius: '16px',
                                        alignItems: 'flex-start',
                                        backgroundColor: 'var(--gray-wood)',
                                        border: 'solid 2px var(--brown-light)'
                                    }
                                }
                            }}
                        />
                    ) : (
                        <>
                            <PetChip
                                active={true}
                                pet={props.booking.petsToBooking[0].pet}
                                checked={!!props.booking.petsToBooking[0].service?.id}
                            />

                            <Box
                                sx={{
                                    gap: 2,
                                    display: 'flex',
                                    flexDirection: 'column',
                                    marginTop: 2
                                }}
                            >
                                {servicesList.map((service, index) => (
                                    <CollapsibleRadio
                                        key={service.id}
                                        title={service.name}
                                        id={`service-${index}`}
                                        content={service.description}
                                        value={service.id.toString()}
                                        description={`Starting at $${service.price}`}
                                        checked={selectedService === service.id.toString()}
                                        onChange={() => changeHandler(service)}
                                    />
                                ))}
                            </Box>
                        </>
                    )}
                </Grid>
            }
        />
    );
};

export default BookingSelectService;
