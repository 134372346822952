import React from 'react';
import './PetBirthdayField.scss';
import { DesktopDatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { DateTime } from 'luxon';
import { Box, TextField } from '@mui/material';
import { AdapterLuxon } from '@mui/x-date-pickers/AdapterLuxon';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCalendarDays } from '@fortawesome/pro-regular-svg-icons';
import { faCaretDown } from '@fortawesome/pro-solid-svg-icons';

export interface PetBirthdayFieldProps {
    defaultValue?: string;
    onChange: (value: string) => void;
}

const PetBirthdayField: React.FC<PetBirthdayFieldProps> = props => {
    const [isOpen, setIsOpen] = React.useState<boolean>(false);
    const [isFocused, setIsFocused] = React.useState<boolean>(false);
    const [value, setValue] = React.useState<DateTime | null>(
        props.defaultValue ? DateTime.fromFormat(props.defaultValue, 'yyyy-MM-dd') : null
    );

    const handleChange = (newValue: DateTime | null) => {
        setValue(newValue);

        if (!newValue) {
            return;
        }

        props.onChange(newValue.toFormat('yyyy-MM-dd'));
    };

    return (
        <Box className="PetBirthdayField">
            <LocalizationProvider dateAdapter={AdapterLuxon}>
                <DesktopDatePicker
                    open={isOpen}
                    onOpen={() => setIsOpen(true)}
                    onClose={() => setIsOpen(false)}
                    label="Birthday"
                    inputFormat="MM/dd/yyyy"
                    className="PetBirthdayField-wrapper"
                    value={value}
                    maxDate={DateTime.now()}
                    onChange={handleChange}
                    renderInput={({ InputProps, ...params }) => (
                        <TextField
                            {...params}
                            required
                            id="petform_list_birthday"
                            InputProps={{
                                ...InputProps,
                                endAdornment: (
                                    <FontAwesomeIcon
                                        icon={faCaretDown}
                                        className="PetBirthdayField-icon--end"
                                    />
                                ),
                                startAdornment: (
                                    <FontAwesomeIcon
                                        icon={faCalendarDays}
                                        className="PetBirthdayField-icon--start"
                                    />
                                )
                            }}
                            InputLabelProps={{
                                shrink: isFocused || isOpen || !!value,
                                style: { marginLeft: 30 }
                            }}
                            onClick={() => setIsOpen(true)}
                            onFocus={() => setIsFocused(true)}
                            onBlur={() => setIsFocused(false)}
                        />
                    )}
                />
            </LocalizationProvider>
        </Box>
    );
};

export default PetBirthdayField;
