import React from 'react';
import { Link } from 'react-router-dom';

import { Box } from '@mui/material';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft } from '@fortawesome/pro-light-svg-icons';

import { cuddlesLogo } from 'assets/images';
import { ProgressBar } from 'model';
import './Header.scss';

interface HeaderProps {
    logo?: string;
    backButton: boolean;
    progress?: ProgressBar;
    //
    onBack?: () => void;
}

export const Header: React.FunctionComponent<HeaderProps> = props => {
    return (
        <Box className="Header-box">
            <div className="Header">
                <Box className="Header-wrapper">
                    <Box>
                        {props.backButton && (
                            <Box className="Header-BackButton" onClick={props.onBack}>
                                <FontAwesomeIcon icon={faArrowLeft} />
                                <span>Back</span>
                            </Box>
                        )}
                    </Box>

                    <Box className="Header-logo-column">
                        <Link to="">
                            <img
                                src={props.logo || cuddlesLogo}
                                className="Header-logo"
                                alt="Cuddles Logo"
                            />
                        </Link>
                    </Box>

                    <Box />
                </Box>
            </div>
            {props.progress?.show && (
                <Box className="Header-progress">
                    <Box
                        className="Header-progress-bar"
                        sx={{ width: props.progress?.width }}
                    ></Box>
                </Box>
            )}
        </Box>
    );
};

export default Header;
