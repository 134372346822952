import React, { useMemo, useState } from 'react';
import { Grid } from '@mui/material';
import { BookingLayout, CustomTextarea, Heading } from 'components';
import { EditBooking } from 'model/Booking';
import { useNonInitialEffect } from '@versiondos/hooks';
import { AgreementSignature, Marketplace } from 'model';
import FormErrorList from 'components/UI/FormsPart/FormErrorList/FormErrorList';
import { BookingStep } from 'components/Booking/Booking';
import { useDispatch, useSelector } from 'react-redux';
import { fetchAgreementsThunk } from 'actions/agreements/AgreementsActions';
import AgreementsList from 'components/Agreements/AgreementsList/AgreementsList';
import { RootState } from 'store';
import { AgreementsState } from 'reducers/agreements/AgreementsState';

interface BookingNotesProps {
    booking: EditBooking;
    marketplace: Marketplace;
    //
    onChange: (booking: EditBooking) => void;
    onBooking: () => void;
}

export const BookingNotes: React.FunctionComponent<BookingNotesProps> = props => {
    const dispatch = useDispatch();

    const agreementsState = useSelector<RootState, AgreementsState>(state => state.agreements);

    const [notes, setNotes] = useState<string>(props.booking.notes ?? '');
    const [agreementsSignatures, setAgreementsSignatures] = useState<Array<AgreementSignature>>(
        props.booking.agreementsSignatures
    );

    const continueHandler = () => {
        props.onBooking();
    };

    const isAgreementsSigned = useMemo(() => {
        return (
            agreementsState.agreements.length === props.booking.agreementsSignatures.length &&
            !agreementsState.loading
        );
    }, [agreementsState.agreements, agreementsState.loading, props.booking.agreementsSignatures]);

    const hasDeposit = useMemo(() => {
        return props.marketplace.depositCategory !== 'none';
    }, [props.marketplace]);

    useNonInitialEffect(() => {
        props.onChange({ ...props.booking, notes, agreementsSignatures });
    }, [notes, agreementsSignatures]);

    React.useEffect(() => {
        dispatch(fetchAgreementsThunk());
    }, []);

    return (
        <BookingLayout
            sx={{
                '.BookingLayout-left-column': {
                    pb: '16px !important'
                }
            }}
            showFooter={{
                disabled: !isAgreementsSigned,
                label: hasDeposit ? 'Continue' : 'Book Now',
                onClick: continueHandler
            }}
            rightColumnMobile={true}
            showResume={props.booking}
            leftColumn={
                <Grid item xs={12}>
                    <Heading title="Booking notes" boxProps={{ sx: { mb: { xs: 3, md: 5 } } }} />

                    <FormErrorList step={BookingStep.Notes} />

                    <CustomTextarea
                        id="booking_notes"
                        placeholder="Anything important to note?"
                        rows={4}
                        //
                        onChange={value => setNotes(value)}
                    />

                    <AgreementsList
                        signatures={agreementsSignatures}
                        onChange={setAgreementsSignatures}
                    />
                </Grid>
            }
        />
    );
};

export default BookingNotes;
