import React, { ReactElement } from 'react';

import { Box, Container, Grid } from '@mui/material';

import './BookingLayout.scss';
import { PrimaryButton } from 'components/UI/Buttons';
import { FullWidthImage } from 'components/Commons';
import { BookingResume } from 'components/Booking';
import { useMarketplace } from 'hooks';
import { EditBooking } from 'model';

interface BookingLayoutProps {
    sx?: object;

    leftColumn?: ReactElement;
    rightColumn?: ReactElement;
    rightColumnMobile?: boolean;
    rightColumnImage?: string;
    hiddenHeader?: boolean;

    showFooter?:
        | false
        | {
              id?: string;
              label?: string;
              disabled: boolean;
              onClick: () => void;
          };
    showResume?: EditBooking;
}

const BookingLayout: React.FunctionComponent<BookingLayoutProps> = props => {
    const marketplace = useMarketplace();

    return (
        <Container
            className={
                props.hiddenHeader
                    ? 'BookingLayout-container BookingLayout-no-header'
                    : 'BookingLayout-container'
            }
            {...{ sx: props.sx }}
        >
            <Grid
                container
                justifyContent="space-between"
                spacing={3}
                mt={0}
                className="BookingLayout-container-grid"
            >
                <Grid item xl={6} md={6} xs={12} className="BookingLayout-left-column">
                    {props.leftColumn}
                </Grid>
                <Grid item xl={5} md={5.5} xs={12} className="BookingLayout-right-column">
                    <Box
                        className={`BookingLayout-right-column-wrapper ${
                            props.rightColumnMobile === true ? '' : 'hide-on-mobile'
                        }`}
                    >
                        {props.showResume && (
                            <>
                                <Box className="BookingLayout-resume-background" />

                                <Grid item xs={12}>
                                    <Grid item xs={12}>
                                        <Box
                                            sx={{
                                                zIndex: 10,
                                                position: 'relative',
                                                pt: { xs: 0, md: 6 },
                                                pl: { xs: 0, md: 5 }
                                            }}
                                        >
                                            <BookingResume
                                                marketplace={marketplace}
                                                booking={props.showResume}
                                                //
                                                showPet={true}
                                                showService={true}
                                                showBilling={true}
                                                showDate={true}
                                            />
                                        </Box>
                                    </Grid>
                                </Grid>
                            </>
                        )}

                        {props.rightColumn}

                        {props.rightColumnImage && (
                            <FullWidthImage hiddenHeader={true} src={props.rightColumnImage} />
                        )}
                    </Box>

                    {props.showFooter && (
                        <Box
                            className="BookingLayout-footer"
                            sx={{
                                pl: { xs: 0, md: 5 }
                            }}
                        >
                            <div className="BookingLayout-footer-divider" />

                            <PrimaryButton
                                sx={{ width: '100%' }}
                                id={props.showFooter.id || 'bookings_continue'}
                                label={props.showFooter?.label ?? 'Continue'}
                                disabled={props.showFooter?.disabled}
                                onClick={props.showFooter?.onClick}
                            />
                        </Box>
                    )}
                </Grid>
            </Grid>
        </Container>
    );
};

export default BookingLayout;
