import React, { useRef } from 'react';
import { DesktopDatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterLuxon } from '@mui/x-date-pickers/AdapterLuxon';
import { DateTime } from 'luxon';
import { Box, TextField } from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCalendarDays } from '@fortawesome/pro-regular-svg-icons';
import { Theme, createStyles, makeStyles } from '@material-ui/core';
import moment, { Moment } from 'moment-timezone';

export interface VaccineExpirationDateFieldProps {
    id: string;
    buttonId: string;
    defaultValue?: string;
    onChange: (value: Moment) => void;
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        button: {
            'gap': 8,
            'height': 45,
            'fontSize': 16,
            'borderRadius': 30,
            'cursor': 'pointer',
            'padding': '0px 20px',
            'alignItems': 'center',
            'display': 'inline-flex',
            'justifyContent': 'center',
            'border': 'solid 1px #000',
            'backgroundColor': '#fff',

            [theme.breakpoints.only('md')]: {
                fontSize: 15,
                padding: '0px 10px'
            },

            '& svg': {
                'marginTop': -2,

                '&:last-child': {
                    fontSize: 14,
                    paddingTop: 3
                }
            },
            '& span': {
                overflow: 'hidden',
                whiteSpace: 'nowrap',
                textOverflow: 'ellipsis'
            },
            '& i': {
                marginLeft: 4,
                fontWeight: 600,
                fontStyle: 'normal',
                color: 'var(--primary-color)'
            }
        }
    })
);

const VaccineExpirationDateField: React.FC<VaccineExpirationDateFieldProps> = props => {
    const classes = useStyles();

    const inputRef = useRef<HTMLInputElement>(null);

    const [isOpen, setIsOpen] = React.useState<boolean>(false);
    const [value, setValue] = React.useState<DateTime | null>(
        props.defaultValue ? DateTime.fromFormat(props.defaultValue, 'yyyy-MM-dd') : null
    );

    const handleChange = (newValue: DateTime | null) => {
        setValue(newValue);

        if (!newValue) {
            return;
        }

        props.onChange(moment(newValue.toFormat('yyyy-MM-dd')));
    };

    return (
        <LocalizationProvider dateAdapter={AdapterLuxon}>
            <DesktopDatePicker
                open={isOpen}
                onOpen={() => setIsOpen(true)}
                onClose={() => setIsOpen(false)}
                inputFormat="MM/dd/yyyy"
                label="Add Expiration Date"
                value={value}
                inputRef={inputRef}
                onChange={handleChange}
                renderInput={({ InputProps, ...params }) => (
                    <>
                        <TextField
                            {...params}
                            id={props.id}
                            InputProps={{
                                ...InputProps,
                                endAdornment: null,
                                startAdornment: <FontAwesomeIcon icon={faCalendarDays} />
                            }}
                            InputLabelProps={{
                                shrink: false
                            }}
                            onClick={() => setIsOpen(true)}
                            style={{ width: 0, height: 0, opacity: 0 }}
                        />

                        <Box
                            id={props.buttonId}
                            className={classes.button}
                            onClick={() => setIsOpen(true)}
                        >
                            <FontAwesomeIcon icon={faCalendarDays} />

                            <span>
                                {value ? (
                                    value.toFormat('MM/dd/yyyy')
                                ) : (
                                    <>
                                        {params.label}
                                        <i>*</i>
                                    </>
                                )}
                            </span>
                        </Box>
                    </>
                )}
            />
        </LocalizationProvider>
    );
};

export default VaccineExpirationDateField;
